<template>
  <div>
    <b-row>
      <b-col>
        <b-card class="header-marcar_consulta bg-gradient-primary">
          <b-row align-v="center">
            <b-col lg="3">
              <h1>
                <feather-icon icon="UserIcon" size="30" class="mr-50" />
                Professores
              </h1>
            </b-col>
            <b-col lg="6">
              <b-form @submit.prevent="fetchUsers">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="filtro.firstName"
                    placeholder="Buscar Professor"
                  />
                </b-input-group>
              </b-form>
            </b-col>
            <b-col lg="3">
              <b-button variant="primary button-right" :to="'/ProfessorLista/cadastrar'">
                Adicionar Professor</b-button
              >
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-card no-body class="mb-0">
      <b-table
        ref="refUserListTable"
        style="min-height: 50vw"
        class="position-relative"
        :items="fetchedUsers?.content"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Não há professores cadastrados"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            
            <b-dropdown-item
              :to="{ name: 'ProfessorEditar', params: { id: data.item.id} }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar Professor</span>
            </b-dropdown-item>
          </b-dropdown>

        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BTable,
  BDropdown,
  BDropdownItem,
  BBadge,
  BLink,
  BMedia,
  BAvatar,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
  BFormTextarea,
  BForm,
} from 'bootstrap-vue'
import { title } from '@/@core/utils/filter'
import { hideSpinner, showSpinner } from '@/services/spinService'
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import flatPickr from 'vue-flatpickr-component'
import usersService from '@/services/usersService'

export default {
  components: {
    BCard,
    BTable,
    BDropdown,
    BDropdownItem,
    BBadge,
    BLink,
    BMedia,
    BAvatar,
    BFormTextarea,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BRow,
    BCol,
    BButton,
    BForm,
    flatPickr,
  },
  data: () => ({
    configFlatPickr: { dateFormat: 'd/m/Y', locale: Portuguese },
    filtro: { role: 4 },
    perPage: 10,
    totalUsers: 0,
    currentPage: 1,
    perPageOptions: [10, 25, 50, 100],
    searchQuery: '',
    sortBy: 'id',
    isSortDirDesc: true,
    roleFilter: null,
    planFilter: null,
    statusFilter: null,
    fetchedUsers: [],
    tableColumns: [
      { key: 'fullName', label: 'Nome', sortable: true },
      { key: 'school.name', label: 'Escola', sortable: true },
      { key: 'discipline', label: 'Disciplina', sortable: true },
      { key: 'email', label: 'Email', sortable: true },
      { key: 'Telefone', label: 'Telefone', sortable: true },
      { key: 'actions', label: 'Ações', sortable: false },
    ],
  }),
  mounted() {
    this.fetchUsers()
  },
  methods: {
    prepareRequest(obj) {
      if (obj.createdAtTo)
        obj.createdAtTo = obj.createdAtTo
          ? `${obj.createdAtTo.split('/').reverse().join('-')} 00:00`
          : ''
      return obj
    },
    async fetchUsers() {
      // showSpinner()
      this.fetchedUsers = await usersService.getAll(
        this.prepareRequest(this.filtro)
      )
      hideSpinner()
    },
    // resolveUserRoleVariant: role => {
    //   if (role === 'paciente') return 'primary'
    //   if (role === 'author') return 'warning'
    //   if (role === 'maintainer') return 'success'
    //   if (role === 'editor') return 'info'
    //   if (role === 'admin') return 'danger'
    //   return 'primary'
    // },
    // resolveUserRoleIcon: role => {
    //   if (role === 'paciente') return 'UserIcon'
    //   if (role === 'author') return 'SettingsIcon'
    //   if (role === 'maintainer') return 'DatabaseIcon'
    //   if (role === 'editor') return 'Edit2Icon'
    //   if (role === 'admin') return 'ServerIcon'
    //   return 'UserIcon'
    // },
    resolveUserStatusText: status => {
      if (status === 'abandono') return 'warning'
      if (status === 'ACTIVE') return 'ativo'
      if (status === 'alta') return 'primary'
      if (status === 'óbito') return 'danger'
      return ''
    },

    resolveUserStatusVariant: status => {
      if (status === 'abandono') return 'warning'
      if (status === 'ACTIVE') return 'success'
      if (status === 'alta') return 'primary'
      if (status === 'óbito') return 'danger'
      return 'primary'
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
