var render = function () {
  var _vm$fetchedUsers;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', [_c('b-card', {
    staticClass: "header-marcar_consulta bg-gradient-primary"
  }, [_c('b-row', {
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('h1', [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "UserIcon",
      "size": "30"
    }
  }), _vm._v(" Professores ")], 1)]), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.fetchUsers.apply(null, arguments);
      }
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "SearchIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "placeholder": "Buscar Professor"
    },
    model: {
      value: _vm.filtro.firstName,
      callback: function callback($$v) {
        _vm.$set(_vm.filtro, "firstName", $$v);
      },
      expression: "filtro.firstName"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "primary button-right",
      "to": '/ProfessorLista/cadastrar'
    }
  }, [_vm._v(" Adicionar Professor")])], 1)], 1)], 1)], 1)], 1), _c('b-card', {
    staticClass: "mb-0",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-table', {
    ref: "refUserListTable",
    staticClass: "position-relative",
    staticStyle: {
      "min-height": "50vw"
    },
    attrs: {
      "items": (_vm$fetchedUsers = _vm.fetchedUsers) === null || _vm$fetchedUsers === void 0 ? void 0 : _vm$fetchedUsers.content,
      "responsive": "",
      "fields": _vm.tableColumns,
      "primary-key": "id",
      "sort-by": _vm.sortBy,
      "show-empty": "",
      "empty-text": "Não há professores cadastrados",
      "sort-desc": _vm.isSortDirDesc
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell(actions)",
      fn: function fn(data) {
        return [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "no-caret": "",
            "right": _vm.$store.state.appConfig.isRTL
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "align-middle text-body",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('b-dropdown-item', {
          attrs: {
            "to": {
              name: 'ProfessorEditar',
              params: {
                id: data.item.id
              }
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        }), _c('span', {
          staticClass: "align-middle ml-50"
        }, [_vm._v("Editar Professor")])], 1)], 1)];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }